/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $( '.flexslider' ).flexslider();
        this.headerWatcher();
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $( '.selected-post' )
          .each(function(count, post) {
            var $post = $( post );
            var $heading = $post.find( 'h4' );
            var link_height = $heading.find( 'a' ).height();

            if( link_height < 40 ) {
              $heading.css( 'padding-top', ( $heading.height() - link_height ) / 2 );
            }
          });

        var $posts = $( '.selected-post' );
        var max_height = 0;

        $( '.odd-section, .home-posts' ).imagesLoaded( function() {
          $posts.each( function( index, post ) {
            var $post = $( post ).find( '.content-wrap' );

            if( $post.height() > max_height ) {
              max_height = $post.height();
            }
          });

          $posts.each( function( index, post ) {
            var $post = $( post ).find( '.content-wrap' );

            if( $post.height() < max_height ) {
              $post.height( max_height );
            }
          });
        });
      },
      headerWatcher: function() {
        var $header = $( 'header.banner' );
        var headerHeight = $header.outerHeight();
        var sliderHeight = $( '#page-slider' ).outerHeight();

        $( window )
          .on( 'scroll', function(event) {
            var currentScrollPos = $( window ).scrollTop();
            var is_banner_scroll = $header.hasClass( 'banner-scroll' );

            if( ( currentScrollPos + headerHeight ) > sliderHeight && ! is_banner_scroll ) {
              $header.addClass( 'banner-scroll' ).find( '.top-section' ).slideToggle(500);
            }
            else if( ( currentScrollPos + headerHeight ) < sliderHeight && is_banner_scroll ) {
              $header.removeClass( 'banner-scroll' ).find( '.top-section' ).slideToggle(500);
            }
          });
      },
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
